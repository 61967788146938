import React, { FunctionComponent } from 'react'

import Layout from '../../components/layout/MonthlyReportLayout'
import { Table, TableBodyRowView } from '../../components/table'

interface LastYearServiceReportProps {
  reportData: any
}

const LastYearServiceReport: FunctionComponent<LastYearServiceReportProps> = ({
  reportData,
}) => {
  return (
    <Layout title="전년도 서비스별 월간 레포트">
      <Table tableHeadTitle="작년">
        <TableBodyRowView
          title="대기업"
          data={reportData?.weportStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="공기업"
          data={reportData?.ncsStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="공무원"
          data={reportData?.sevenGongStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="엔지닉"
          data={reportData?.enginicStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="토커비"
          data={reportData?.talkerbeStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="오프라인"
          data={reportData?.offlineStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="기업분석"
          data={reportData?.companyStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="합계"
          data={reportData?.totalStats.lastYearSalesAmountData}
          hasRowYellowColor
        />
      </Table>
    </Layout>
  )
}

export default LastYearServiceReport
