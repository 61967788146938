import React, { FunctionComponent } from 'react'
import Layout from '../../components/layout/MonthlyReportLayout'
import { Table, TableBodyRowView } from '../../components/table'
import { EnumServiceTypes } from '../../../../../../app.feature/sales/sales-stats/types/service-type'
import moment from 'moment'
import styled from 'styled-components'

interface LastYearServiceReportProps {
  reportData: any
}

const SalesByServiceReport: FunctionComponent<LastYearServiceReportProps> = ({
  reportData,
}) => {
  return (
    <Layout title="서비스별 월간 레포트">
      {Object.keys(reportData).map(key => {
        const thisYear = `${moment().year()}`
        const lastYear = `${moment().year() - 1}`

        if (key !== 'totalStats') {
          return (
            <StyledWrapperLastReport key={`wrapper-${key}`}>
              {/*<div className="sub-title">{EnumServiceTypes[key]}</div>*/}
              <Table tableHeadTitle={EnumServiceTypes[key]} key={`table-${key}`}>
                <TableBodyRowView
                  title={thisYear}
                  data={reportData[key].thisYearSalesAmountData}
                />
                <TableBodyRowView
                  title={lastYear}
                  data={reportData[key].lastYearSalesAmountData}
                />
                <TableBodyRowView
                  title="증감"
                  data={reportData[key].salesDifferenceAmountData}
                />
              </Table>
            </StyledWrapperLastReport>
          )
        }
      })}
    </Layout>
  )
}

export default SalesByServiceReport

const StyledWrapperLastReport = styled.div`
  margin: 10px 0 10px;
  .sub-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  }
`
