import gql from 'graphql-tag'
import { useRefundReportContentRefundStatsQuery } from 'generated/graphql'

interface RefundReportDataProps {
  date: any
}

function useRefundReportData({ date }: RefundReportDataProps) {
  const {
    loading: refundStatsLoading,
    data: refundStatsData,
  } = useRefundReportContentRefundStatsQuery({
    variables: {
      date,
    },
  })

  return {
    refundStatsLoading,
    refundStatsData,
  }
}

export default useRefundReportData

gql`
  query RefundReportContentRefundStats($date: Date!) {
    # 전체 월간 레포트
    totalStats: contentRefundStats(
      filterBy: { service: TOTAL, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_RefundStats
    }

    # 2020년 서비스별 월간 레포트
    weportStats: contentRefundStats(
      filterBy: { service: WEPORT, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
    ncsStats: contentRefundStats(
      filterBy: { service: NCS, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
    sevenGongStats: contentRefundStats(
      filterBy: {
        service: SEVEN_GONG
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
    enginicStats: contentRefundStats(
      filterBy: {
        service: ENGINIC
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
    talkerbeStats: contentRefundStats(
      filterBy: {
        service: TALKERBE
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
    offlineStats: contentRefundStats(
      filterBy: {
        service: OFFLINE
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
    companyStats: contentRefundStats(
      filterBy: {
        service: COMPANY
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_RefundStats
    }
  }
`

gql`
  fragment ThisYearServiceReport_RefundStats on ContentRefundStatConnection {
    nodes {
      refundAmount
    }
    summary {
      totalRefundAmount
    }
    nodesOfLastYear {
      refundAmount
    }
    yearlySummaryOfLastYear {
      totalRefundAmount
    }
    growthVSLastYear {
      refundAmountGrowthRate
      refundAmountGrowthAmount
    }
    growthSummaryVSLastYear {
      totalRefundAmountGrowthAmount
      totalRefundAmountGrowthRate
    }
  }
`
