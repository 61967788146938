import React from 'react'

import { Layout } from '../../components/layout'
import { Table, TableBodyRowView } from '../../components/table'

type TProps = {
  totalStats: {
    thisYearSalesAmountData: number[]
    lastYearSalesAmountData: number[]
    salesDifferenceAmountData: number[]
    salesAmountGrowRateData: number[]
  }
}

const AllReport: React.FC<TProps> = ({ totalStats }) => {
  return (
    <Layout title="전체 월간 레포트">
      <Table>
        <TableBodyRowView
          title="올해 매출"
          data={totalStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="전년도 매출"
          data={totalStats.lastYearSalesAmountData}
        />
        <TableBodyRowView
          title="전년대비"
          data={totalStats.salesAmountGrowRateData}
          hasRowYellowColor
          hasPercent
        />
        <TableBodyRowView
          title="전년대비 증감"
          data={totalStats.salesDifferenceAmountData}
        />
      </Table>
    </Layout>
  )
}

export default AllReport
