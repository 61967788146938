import React, { FunctionComponent, useState } from 'react'

import moment from 'moment'
import usePurchaseReportData from '../hooks/usePurchaseReportData'
import HeaderSubject from '../../../../../app.components/header/HeaderSubject'
import MonthlySalesTable from './components/MonthlySalesTable'
import useRefundReportData from '../hooks/useRefundReportData'

const ScreenMonthlySalseReport: FunctionComponent = () => {
  const [date] = useState(() => moment().valueOf())

  const { purchaseStatsLoading, purchaseStatsData } = usePurchaseReportData({
    date,
  })

  const { refundStatsLoading, refundStatsData } = useRefundReportData({
    date,
  })

  if (purchaseStatsLoading || refundStatsLoading) {
    return <div>..loading</div>
  }

  if (!(purchaseStatsData && refundStatsData)) {
    return null
  }

  return (
    <>
      <HeaderSubject subject="매출 월간 레포트" />
      <MonthlySalesTable
        purchaseStatsData={purchaseStatsData}
        refundStatsData={refundStatsData}
      />
    </>
  )
}

export default ScreenMonthlySalseReport
