export const buildReportData = ({
  purchase,
  refund,
}): Record<
  string,
  {
    thisYearSalesAmountData: number[]
    lastYearSalesAmountData: number[]
    salesDifferenceAmountData: number[]
    salesAmountGrowRateData: number[]
  }
> => {
  return Object.keys(purchase).reduce((acc, key) => {
    const _purchase = purchase[key]
    const _refund = refund[key]

    const thisYearSalesAmountData = _purchase.nodes
      .map(
        ({ salesAmount }, index) => salesAmount - _refund.nodes[index].refundAmount,
      )
      .concat(_purchase.summary.totalSalesAmount - _refund.summary.totalRefundAmount)

    const lastYearSalesAmountData = _purchase.nodesOfLastYear
      .map(
        ({ salesAmount }, index) =>
          salesAmount - _refund.nodesOfLastYear[index].refundAmount,
      )
      .concat(
        _purchase.yearlySummaryOfLastYear.totalSalesAmount -
          _refund.yearlySummaryOfLastYear.totalRefundAmount,
      )

    const salesDifferenceAmountData = _purchase.growthVSLastYear
      .map(
        ({ salesAmountGrowthAmount }, index) =>
          salesAmountGrowthAmount -
          _refund.growthVSLastYear[index].refundAmountGrowthAmount,
      )
      .concat(
        _purchase.growthSummaryVSLastYear.totalSalesAmountGrowthAmount -
          _refund.growthSummaryVSLastYear.totalRefundAmountGrowthAmount,
      )

    const salesAmountGrowRateData = _purchase.growthVSLastYear
      .map(
        ({ salesAmountGrowthRate }, index) =>
          salesAmountGrowthRate -
          _refund.growthVSLastYear[index].refundAmountGrowthRate,
      )
      .concat(
        _purchase.growthSummaryVSLastYear.totalSalesAmountGrowthRate -
          _refund.growthSummaryVSLastYear.totalRefundAmountGrowthRate,
      )

    acc[key] = {
      thisYearSalesAmountData,
      lastYearSalesAmountData,
      salesDifferenceAmountData,
      salesAmountGrowRateData,
    }

    return acc
  }, {})
}
