import React from 'react'
import gql from 'graphql-tag'
import Layout from '../../components/layout/MonthlyReportLayout'
import { Table, TableBodyRowView } from '../../components/table'

type TProps = {
  reportData: any
}

const ThisYearServiceReport: React.FC<TProps> = ({ reportData }) => {
  return (
    <Layout title="올해 서비스별 월간 레포트">
      <Table tableHeadTitle="올해">
        <TableBodyRowView
          title="대기업"
          data={reportData?.weportStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="공기업"
          data={reportData?.ncsStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="공무원"
          data={reportData?.sevenGongStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="엔지닉"
          data={reportData?.enginicStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="토커비"
          data={reportData?.talkerbeStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="오프라인"
          data={reportData?.offlineStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="기업분석"
          data={reportData?.companyStats.thisYearSalesAmountData}
        />
        <TableBodyRowView
          title="합계"
          data={reportData?.totalStats.thisYearSalesAmountData}
          hasRowYellowColor
        />
      </Table>
    </Layout>
  )
}

export default ThisYearServiceReport

gql`
  fragment ThisYearServiceReportView_ContentPurchaseStats on ContentPurchaseStatConnection {
    nodes {
      salesAmount
    }
    summary {
      totalSalesAmount
    }
    nodesOfLastYear {
      salesAmount
    }
    yearlySummaryOfLastYear {
      totalSalesAmount
    }
    growthVSLastYear {
      salesAmountGrowthAmount
    }
    growthSummaryVSLastYear {
      totalSalesAmountGrowthAmount
    }
  }
`
