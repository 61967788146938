import React from 'react'
import ButtonDownload from '../../../../app.components/download/ButtonDownload'

type TProps = {
  reportData: any | string[]
}

const PurchaseReportDownload: React.FC<TProps> = ({ reportData }) => {
  const currentYear = new Date().getFullYear()
  const lastYear = currentYear - 1

  const requestTransformData = () => {
    const transformedData = [
      { '': '전체 월간 레포트' },
      { '': '', ...monthly },
      {
        '': '올해 매출',
        ...reportData.totalStats.thisYearSalesAmountData,
      },
      {
        '': '전년도 매출',
        ...reportData.totalStats.lastYearSalesAmountData,
      },
      {
        '': '전년 대비',
        ...reportData.totalStats.salesAmountGrowRateData.map(
          item => `${Math.round(item * 10000) / 100}%`,
        ),
      },
      {
        '': '전년대비 증감',
        ...reportData.totalStats.salesDifferenceAmountData,
      },
      {},
      { '': '올해 서비스별 월간 레포트' },
      { '': currentYear, ...monthly },
      {
        '': '대기업',
        ...reportData.weportStats.thisYearSalesAmountData,
      },
      {
        '': '공기업',
        ...reportData.ncsStats.thisYearSalesAmountData,
      },
      {
        '': '공무원',
        ...reportData.sevenGongStats.thisYearSalesAmountData,
      },
      {
        '': '엔지닉',
        ...reportData.enginicStats.thisYearSalesAmountData,
      },
      {
        '': '토커비',
        ...reportData.talkerbeStats.thisYearSalesAmountData,
      },
      {
        '': '오프라인',
        ...reportData.offlineStats.thisYearSalesAmountData,
      },
      {
        '': '기업분석',
        ...reportData.companyStats.thisYearSalesAmountData,
      },
      {
        '': '합계',
        ...reportData.totalStats.thisYearSalesAmountData,
      },
      {},
      { '': '전년도 서비스별 월간 레포트' },
      { '': lastYear, ...monthly },
      {
        '': '대기업',
        ...reportData.weportStats.lastYearSalesAmountData,
      },
      {
        '': '공기업',
        ...reportData.ncsStats.lastYearSalesAmountData,
      },
      {
        '': '공무원',
        ...reportData.sevenGongStats.lastYearSalesAmountData,
      },
      {
        '': '엔지닉',
        ...reportData.enginicStats.lastYearSalesAmountData,
      },
      {
        '': '토커비',
        ...reportData.talkerbeStats.lastYearSalesAmountData,
      },
      {
        '': '오프라인',
        ...reportData.offlineStats.lastYearSalesAmountData,
      },
      {
        '': '기업분석',
        ...reportData.companyStats.lastYearSalesAmountData,
      },
      {
        '': '합계',
        ...reportData.totalStats.lastYearSalesAmountData,
      },
      {},
      { '': '서비스별 월간 레포트' },
      { '': '대기업', ...monthly },
      {
        '': currentYear,
        ...reportData.weportStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.weportStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.weportStats.salesDifferenceAmountData,
      },
      {},
      { '': '공기업', ...monthly },
      {
        '': currentYear,
        ...reportData.ncsStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.ncsStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.ncsStats.salesDifferenceAmountData,
      },
      {},
      { '': '공무원', ...monthly },
      {
        '': currentYear,
        ...reportData.sevenGongStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.sevenGongStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.sevenGongStats.salesDifferenceAmountData,
      },
      {},
      { '': '엔지닉', ...monthly },
      {
        '': currentYear,
        ...reportData.enginicStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.enginicStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.enginicStats.salesDifferenceAmountData,
      },
      {},
      { '': '토커비', ...monthly },
      {
        '': currentYear,
        ...reportData.talkerbeStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.talkerbeStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.talkerbeStats.salesDifferenceAmountData,
      },
      {},
      { '': '오프라인', ...monthly },
      {
        '': currentYear,
        ...reportData.offlineStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.offlineStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.offlineStats.salesDifferenceAmountData,
      },
      {},
      { '': '기업분석', ...monthly },
      {
        '': currentYear,
        ...reportData.companyStats.thisYearSalesAmountData,
      },
      {
        '': lastYear,
        ...reportData.companyStats.lastYearSalesAmountData,
      },
      {
        '': '증감',
        ...reportData.companyStats.salesDifferenceAmountData,
      },
    ]
    return transformedData
  }

  const monthly = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
    '합계',
  ]

  return (
    <ButtonDownload
      fileName="매출월간레포트"
      requestTransformData={requestTransformData}
    />
  )
}

export default PurchaseReportDownload
