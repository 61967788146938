import moment from 'moment'

const createSalesOrRefundList = ({ data, key }) => {
  return data.map(({ startDate, nodes }) => {
    const date = moment(startDate).format('YYYY-MM-DD')
    const day = moment(date).format('dddd')
    const item = nodes.reduce(
      (acc, node) => {
        if (Object.hasOwn(node, key)) {
          acc[`${node.service.toLowerCase()}Amount`] = node[key]
          acc.totalAmount += node[key]
        }

        return acc
      },
      { totalAmount: 0 },
    )

    return {
      startDate: date,
      day,
      ...item,
    }
  })
}

export const calculateSalesStats = ({ purchase, refund }) => {
  const purchaseRow = createSalesOrRefundList({ data: purchase, key: 'salesAmount' })
  const refundRow = createSalesOrRefundList({ data: refund, key: 'refundAmount' })

  return purchaseRow.reduce((list, { startDate, day, ...row }) => {
    const refundItem = refundRow.find(refund => refund.startDate === startDate)
    if (!refundItem) return list

    const result = { startDate, day }

    Object.keys(row).forEach(key => {
      result[key] = row[key] - refundItem[key]
    })

    return [...list, result]
  }, [])
}
