import gql from 'graphql-tag'
import { usePurchaseReportContentPurchaseStatsQuery } from 'generated/graphql'

interface PurchaseReportDataProps {
  date: any
}

function usePurchaseReportData({ date }: PurchaseReportDataProps) {
  const {
    loading: purchaseStatsLoading,
    data: purchaseStatsData,
  } = usePurchaseReportContentPurchaseStatsQuery({
    variables: {
      date,
    },
  })

  return {
    purchaseStatsLoading,
    purchaseStatsData,
  }
}

export default usePurchaseReportData

gql`
  query PurchaseReportContentPurchaseStats($date: Date!) {
    # 전체 월간 레포트
    totalStats: contentPurchaseStats(
      filterBy: { service: TOTAL, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }

    # 2020년 서비스별 월간 레포트
    weportStats: contentPurchaseStats(
      filterBy: { service: WEPORT, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
    ncsStats: contentPurchaseStats(
      filterBy: { service: NCS, contentType: TOTAL, period: MONTHLY, date: $date }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
    sevenGongStats: contentPurchaseStats(
      filterBy: {
        service: SEVEN_GONG
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
    enginicStats: contentPurchaseStats(
      filterBy: {
        service: ENGINIC
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
    talkerbeStats: contentPurchaseStats(
      filterBy: {
        service: TALKERBE
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
    offlineStats: contentPurchaseStats(
      filterBy: {
        service: OFFLINE
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
    companyStats: contentPurchaseStats(
      filterBy: {
        service: COMPANY
        contentType: TOTAL
        period: MONTHLY
        date: $date
      }
    ) {
      ...ThisYearServiceReport_PurchaseStats
    }
  }
`

gql`
  fragment ThisYearServiceReport_PurchaseStats on ContentPurchaseStatConnection {
    nodes {
      salesAmount
    }
    summary {
      totalSalesAmount
    }
    nodesOfLastYear {
      salesAmount
    }
    yearlySummaryOfLastYear {
      totalSalesAmount
    }
    growthVSLastYear {
      salesAmountGrowthRate
      salesAmountGrowthAmount
    }
    growthSummaryVSLastYear {
      totalSalesAmountGrowthRate
      totalSalesAmountGrowthAmount
    }
  }
`
