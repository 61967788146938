import React from 'react'
import { Table } from 'antd'
import ColumnSalesStats from './ColumnSalesStats'
import { calculateSalesStats } from '../module/buildSalesStatsData'
import SalesStatsDownload from './SalesStatsDownload'
import styled from 'styled-components'

type TProps = {
  data: any | string[]
  queryFilter: any | string[]
  setQueryFilter: any
}
const SalesStatsListTable: React.FC<TProps> = ({
  data,
  queryFilter,
  setQueryFilter,
}) => {
  const dataSource = calculateSalesStats({
    purchase: data.servicePurchaseStats.nodes,
    refund: data.serviceRefundStats.nodes,
  })

  const handlePageChange = (page: number, pageSize: number) => {
    if (page || pageSize) {
      const chancePagination = {
        pagination: {
          ...(page ? { page: page } : { page: queryFilter.pagination.page }),
          ...(pageSize
            ? { pageSize: pageSize }
            : { pageSize: queryFilter.pagination.pageSize }),
        },
        startDate: queryFilter?.startDate,
        endDate: queryFilter?.endDate,
        period: queryFilter?.period,
      }
      setQueryFilter(chancePagination)
    }
  }

  return (
    <StypeWrapper>
      <div className="top-btn-wrapper">
        <SalesStatsDownload queryFilter={queryFilter} />
      </div>
      <Table
        rowKey={record => record.startDate}
        bordered
        columns={ColumnSalesStats(queryFilter.period)}
        dataSource={dataSource}
        pagination={{
          position: ['bottomCenter'],
          current: queryFilter.pagination.page,
          defaultPageSize: queryFilter?.pagination?.pageSize,
          total: data.servicePurchaseStats.totalCount,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100],
          onChange: handlePageChange,
        }}
      />
    </StypeWrapper>
  )
}

export default SalesStatsListTable

const StypeWrapper = styled.div`
  margin: 10px 0 20px;
  .top-btn-wrapper {
    float: right;
    margin-bottom: 20px;
  }
`
