import gql from 'graphql-tag'
import { useGqlSalesStatsQuery } from '../../../../generated/graphql'

function useSalesStatsData({ startDate, endDate, period, pagination }) {
  const { data: salesStatsData, loading: salesStatsLoading } = useGqlSalesStatsQuery(
    {
      variables: {
        startDate,
        endDate,
        period,
        pagination,
      },
    },
  )
  return {
    salesStatsData,
    salesStatsLoading,
  }
}

export default useSalesStatsData

gql`
  query gqlSalesStats(
    $startDate: Date!
    $endDate: Date!
    $period: StatPeriod!
    $pagination: Pagination
  ) {
    servicePurchaseStats(
      filterBy: { startDate: $startDate, endDate: $endDate, period: $period }
      pagination: $pagination
    ) {
      nodes {
        startDate
        endDate
        nodes {
          salesAmount
          service
        }
      }
      totalCount
    }

    serviceRefundStats(
      filterBy: { startDate: $startDate, endDate: $endDate, period: $period }
      pagination: $pagination
    ) {
      nodes {
        startDate
        endDate
        nodes {
          refundAmount
          service
        }
      }
      totalCount
    }
  }
`
