import PurchaseReportDownload from '../../../../../../app.feature/sales/sales-monthly-report/components/PurchaseReportDownload'
import AllReport from './AllReport'
import ThisYearServiceReport from './ThisYearServiceReport'
import LastYearServiceReport from './LastYearServiceReport'
import React from 'react'
import { buildReportData } from '../module/buildSalesReportData'
import SalesByServiceReport from './SalesByServiceReport'

const MonthlySalesTable = ({ purchaseStatsData, refundStatsData }) => {
  const reportData = buildReportData({
    purchase: purchaseStatsData,
    refund: refundStatsData,
  })

  return (
    <>
      <PurchaseReportDownload reportData={reportData} />
      <AllReport totalStats={reportData.totalStats} />
      <ThisYearServiceReport reportData={reportData} />
      <LastYearServiceReport reportData={reportData} />
      <SalesByServiceReport reportData={reportData} />
    </>
  )
}

export default MonthlySalesTable
